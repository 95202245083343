import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, CSSProperties } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {GetAdministrateurs, GetAdministrateur, DeleteAdministrateur, SaveAdministrateur} from '../functions/api';
import SPSLoader from './SPSLoader';



function Administrateurs(props) {

  const [stateWindowEdit, setStateWindowEdit] = useState(false);
  const [datas, SetDatas] = useState([]);
  const [theData, SetTheData] = useState([]);
  
  const [uniqueId, setUniqueId] = useState(0);
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephoneMobile, setTelephoneMobile] = useState("");
  const [telephoneFixe, setTelephoneFixe] = useState("");
  const [password, setPassword] = useState("");

  const [editPassword, setEditPasword] = useState(false);

  const [filterSearch, setFilterSearch] = useState("");
  
  var idtimer = 0;
  let [loading, setLoading] = useState(false);
  
  
  useEffect(() => {
    if(stateWindowEdit == false)
    {
      console.log("recherche des datas");
      GetAdministrateurs(filterSearch, SetDatas);
    }
    //else
    //  setUpdateNotSaved(false);
    setLoading(false);
  }, [stateWindowEdit]);

  useEffect(() => {
    console.log(JSON.stringify(theData[0]));
    if(theData.length > 0 && theData[0].id != undefined)
    {
      const data = theData[0];
      setLoading(true);
      setUniqueId(data.id);
      setUsername(data.username?data.username:'');
      setEmail(data.email?data.email:"");
      setFirstName(data.first_name?data.first_name:"");
      setLastName(data.last_name?data.last_name:"");
      setTelephoneMobile(data.telephone_mobile?data.telephone_mobile:"");
      setTelephoneFixe(data.telephone_fixe?data.telephone_fixe:"");
      setPassword("");
      setStateWindowEdit(true);
      setLoading(false);
    }
  }, [theData]);

 

  useEffect(() => {
    GetAdministrateurs(filterSearch, SetDatas);
  }, [filterSearch]);
  
  const handleDeleteData = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cet administrateur ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteAdministrateur(id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };

  const afterDelete = () => {
    GetAdministrateurs(filterSearch, SetDatas);
  };

  const handleAdd = (event) => {
    clearFields();
    
    setStateWindowEdit(true);
  };

  const clearFields = () => {
    setUniqueId(0);
    setUsername("");
    setEmail("");
    setFirstName("");
    setLastName("");
    setTelephoneMobile("");
    setTelephoneFixe("");
    setPassword("");
    setEditPasword(false);
  }

  const handleCancel= (event) => {
    clearFields();
    setStateWindowEdit(false);
  };
  const handleClickEdit = (id) => {
    setLoading(true);
    GetAdministrateur(id, SetTheData);
  };

  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    setLoading(true);
    const body = makeobject();
    SaveAdministrateur (body, afterSubmit);
  };

  const afterSubmit = () => {
    clearFields();
    setStateWindowEdit(false);
  }

  function makeobject()
  {
    const body = {
      id:uniqueId,
      username: userName,
      email: email,
      first_name: firstName,
      last_name: lastName,
      telephone_mobile: telephoneMobile,
      telephone_fixe: telephoneFixe,
      password: password
    };
    return body;
  }
    
  const handleBack = (event) => {
    
    props.screenHandling(0);
  };

  const handleShowPassword = () => {
    setEditPasword(true);
  }
  
  return (
    <div className={props.screenVisible?"animscreen visible":"animscreen hidden"} id="administrateurs_screen">
      <div className={stateWindowEdit==false?"liste containerxl":"liste containerxl hidden"} >
          
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6"><h1 className="h4 py-3">Administrateurs</h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={handleAdd}><FontAwesomeIcon icon={faPlus} /></button></div>
            </div>
          
          <SPSLoader load={loading} />
          <div className="content-screen">
            <div className="container">
              <div className="row">
                <div className="col-md-10 text-center my-2">
                  <input type="text" className="form-control" placeholder='Recherche...' onChange={e => { setFilterSearch(e.target.value); } } value={filterSearch} />
                </div>
                <div className="col-md-2 text-center my-2">
                  {datas.length} enregistrement{datas.length > 1?'s':''}
                </div>
              </div>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="px-0">
                    {datas.length > 0?
                    <ul className="container liste-data">
                      {datas.map((r) =>(
                      <li className="row mb-2 alternate-color" key={r.id}  >
                          <div className="col-10" onClick={() => handleClickEdit(r.id)}>
                            <strong>{r.first_name} {r.last_name}</strong><br />
                            {r.telephone_mobile} {r.telephone_fixe} {r.email}
                          </div>
                          <div className="col-2 text-end col-action">
                              <div className="tools-actions">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteData(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                              </div>
                          </div>
                      </li>
                      ))}
                    </ul>:<div className="text-muted text-center">Aucun enregistrement</div>
                    }
                    <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer un administrateur</button></div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
            
          
          
    </div>
    <div className={stateWindowEdit?"saisie containerxl":"saisie containerxl hidden"}>
      <div className="row headScreen">
        <div className="col-3 text-start py-2"><button className="btn btn-link" type="button" onClick={handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
        <div className="col-6"><h1 className="h4 py-3">Administrateur</h1></div>
        <div className="col-3"></div>
      </div>
      <SPSLoader load={loading} />
      <div className="container py-3">
        <form onSubmit={handleSubmit}>
          <div className="row">
          <div className="col-md-6">
              <input type="hidden" name="id" value={uniqueId} />
              <div className="form-group mb-3 row">
                <label className="col-md-2">Nom d'utilisateur</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="UserName" value={userName} onChange={e => { setUsername(e.target.value); }} />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Prénom</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="FirstName" value={firstName} onChange={e => { setFirstName(e.target.value); }} />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Nom</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="LastName" value={lastName} onChange={e => { setLastName(e.target.value); }} />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Email</label>
                <div className="col-md-10">
                  <input type="email" className="form-control" name="Email" value={email} onChange={e => { setEmail(e.target.value); }} />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Mot de passe</label>
                <div className="col-md-10">
                  {editPassword?<input type="password" className="form-control" name="Password" value={password} onChange={e => { setPassword(e.target.value); }} />:
                  <button type="button" className="btn btn-default" onClick={handleShowPassword}>Modifier</button>
                  }
                  
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Téléphone Mobile</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="TelephoneMobile" value={telephoneMobile} onChange={e => { setTelephoneMobile(e.target.value); }} />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Téléphone Fixe</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="TelephoneFixe" value={telephoneFixe} onChange={e => { setTelephoneFixe(e.target.value); }} />
                </div>
              </div>
              
              
            </div>
            
            
          </div>
          <div className="text-end mt-3 p-3 border-top">
            <button className="btn btn-primary" type="submit">Valider</button>
          </div>
        </form>
        
      </div>
    </div>
    </div>
  );

  
}

export default Administrateurs;
