import axios from "axios";
export function AuthToken(check=false)
{
    const authInfos = localStorage.getItem('vigivelAuth');
    var tmpToken = '';
    if(authInfos)
    {
        const authObject = JSON.parse(authInfos);
        if(authObject.token)
        {
            tmpToken = authObject.token;
            // si nous sommes en ligne on vérifie le token
            if(check)
            {
                ActionBadToken(ClearToken);
            }
        }
        
    }
    return tmpToken;
}
const token = AuthToken();
const XApi = axios.create({
  baseURL: process.env.REACT_APP_USER_API_BASE_URL, 
  headers : { Authorization : 'Bearer ' + token },
  timeout: 15000
});
export function ClearToken()
{
    localStorage.removeItem('vigivelAuth');
}


export async function UserLogin(login, password, afterLogin)
{
    
  try {
    const body = {username: login, password: password};
    const res = await XApi.post('/adminlogin', body);
    
    if(res.data.userData.token)
    {
      localStorage.setItem('vigivelAuth', JSON.stringify(res.data.userData));
      afterLogin();
    }
  }
  catch(err)
  {
    alert("Veuillez vérifier votre connexion internet!")
  }
}

export async function Logout(afterLogout)
{
    
  try {
    const res = await XApi.get('/logout');
    if(res.data)
    {
        localStorage.removeItem('vigivelAuth');
      afterLogout();
    }
  }
  catch(err) {
    localStorage.removeItem('vigivelAuth');
    afterLogout();
  }
}

export async function GetUsers(SetUsers)
{
  try {
    const result = await XApi.get('/');
    SetUsers(result.data);
  } catch(err) {
    alert("Veuillez vérifier votre connexion internet !");
  }
}

export async function GetUser(id, SetUser)
{
  try {
    const result = await XApi.get('/'+id);
    SetUser(result.data);
  } catch(err) {
    alert("Veuillez vérifier votre connexion internet !");
  }
}

export async function DeleteUser(id, afterDelete)
{
  try {
    const result = await XApi.delete('/'+id);
    afterDelete();
  } catch(err) {
    alert("Veuillez vérifier votre connexion internet !");
  }
}

export async function SaveUser(newuser, setShowForm)
{
  if(window.SpsServerAvailable)
  {
    var status = 0;
    // si l'utilisateur est connecté, on met à jour les données sur le serveur
    const body = {
      Active: newuser.Active, 
      Type: newuser.Type, 
      FirstName: newuser.FirstName, 
      Name: newuser.Name, 
      Poste: newuser.Poste, 
      Email: newuser.Email,
      MailSignature: newuser.MailSignature, 
      Password: newuser.Password
    };
    var result = [];
    try {
      if(newuser.id > 0)
      {
        result = await XApi.put("/"+newuser.id, body);
        setShowForm(false);
      }
      else
      {
        result = await XApi.post("/", body);
        setShowForm(false);
      }
      if(result.data.length > 0 && result.data[0].id != undefined)
        status = 1;
    }
    catch(err) {
      alert("Veuillez vérifier votre connexion internet !");
    }
  }
  else
    setShowForm(false);
  
}

export async function UserQuickList(SetUsers)
{
  try {
    const result = await XApi.get('/list/');
    SetUsers(result.data);
  }
  catch(err) {

  }
}

export async function ActionBadToken(actionKO)
{
  // si nous sommes en ligne on vérifie le token
  
    var t = AuthToken();
    if(t != "")
    {
      try {
        const result = await XApi.post('/checktoken', t);
        if(result.data.result == 0)
        {
          //actionKO();
        }
      }
      catch(err) {
        // si nous n'y parvenons pas, on passe en mode hors ligne
        window.SpsServerAvailable = false;
      }
    }
  
}

export async function SaveAccount(body, afterSave)
{
  if(window.SpsServerAvailable)
  {
    try {
      await XApi.put("/account/", body);
      
      afterSave(0);
    }
    catch(err) {

    }
  }
}

export async function GetAccount(loadAccount)
{
  if(window.SpsServerAvailable)
  {
    try {
      const result = await XApi.get("/account");
        
      loadAccount(result.data);
    }
    catch(err) {

    }
  }
}

export async function CheckNotification(setNotifications)
{
  if(window.SpsServerAvailable)
  {
    try {
      const result = await XApi.get("/notifications/");
        
      setNotifications(result.data);
    }
    catch(err) {

    }
  }
}


/******************************************************
 *             Gestion generaliste
 * 
 ******************************************************/
export async function GetListeAPI(resource, search, setDatas)
{
    try {
        const result = await XApi.get(resource+'?search='+search);
        setDatas(result.data);
      }
      catch(err)
      {
        console.log("Erreur Get "+resource);
      }
}

export async function GetFicheApi(resource, id, setDatas)
{
    try {
      const result = await XApi.get(resource+'?id='+id);
        setDatas(result.data);
      }
      catch(err)
      {
        console.log("Erreur Get "+resource);
      }
}

export async function SaveApi(resource, data, afterSave)
{
    try {
        const result = await XApi.post(resource, data);
        afterSave(result.data);
      }
      catch(err)
      {
        console.log("Erreur Save "+resource);
        console.log(JSON.stringify(err));
      }
}

export async function DeleteApi(resource, id, afterDelete)
{
    try {
        const result = await XApi.delete(resource+'?id='+id);
        afterDelete();
      }
      catch(err)
      {
        console.log("Erreur Delete "+resource);
      }
}

/******************************************************
 *             Gestion des administrateurs
 * 
 ******************************************************/

export async function GetAdministrateurs(search, setDatas)
{
    try {
        const result = await XApi.get('administrateurs?search='+search);
        setDatas(result.data);
      }
      catch(err)
      {
        console.log("Erreur Get Administrateurs");
      }
}

export async function GetAdministrateur(id, setDatas)
{
    try {
        const result = await XApi.get('administrateur?id='+id);
        setDatas(result.data);
      }
      catch(err)
      {
        console.log("Erreur Get Administrateur");
      }
}

export async function SaveAdministrateur(data, afterSave)
{
    try {
        const result = await XApi.post('administrateur', data);
        afterSave();
      }
      catch(err)
      {
        console.log("Erreur Save Administrateur");
      }
}

export async function DeleteAdministrateur(id, afterDelete)
{
    try {
        const result = await XApi.delete('administrateur?id='+id);
        afterDelete();
      }
      catch(err)
      {
        console.log("Erreur Delete Administrateur");
      }
}

/******************************************************
 *             Gestion des devices
 * 
 ******************************************************/

export async function GetDevices(search, setDatas)
{
    try {
        const result = await XApi.get('devices?search='+search);
        setDatas(result.data);
      }
      catch(err)
      {
        console.log("Erreur Get Administrateurs");
      }
}

export async function GetDevice(id, setDatas)
{
    try {
        const result = await XApi.get('device?id='+id);
        setDatas(result.data);
      }
      catch(err)
      {
        console.log("Erreur Get Administrateur");
      }
}

export async function SaveDevice(data, afterSave)
{
    try {
        const result = await XApi.post('device', data);
        afterSave();
      }
      catch(err)
      {
        console.log("Erreur Save Administrateur");
      }
}

export async function DeleteDevice(id, afterDelete)
{
    try {
        const result = await XApi.delete('device?id='+id);
        afterDelete();
      }
      catch(err)
      {
        console.log("Erreur Delete Administrateur");
      }
}
