import { useEffect, useState } from "react";
import Login from "./Login";
import { AuthToken, Logout } from "../functions/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faUser} from '@fortawesome/free-solid-svg-icons'
import {SPSLogout} from "../functions/SPSUsers";
import Dropdown from 'react-bootstrap/Dropdown';


import logo_vigivel from '../resources/images/LogoVigivel2024.png';
import ico_admin from '../resources/images/admin.png';
import ico_exploitations from '../resources/images/exploitations.png';
import ico_capteurs from '../resources/images/capteurs.png';
import ico_account from '../images/account48.png';

import packageInfo from '../../package.json';
import Administrateurs from "./administrateurs";
import Capteurs from "./capteurs";
import Exploitations from "./exploitations";


function Dashboard(props) {
  const [screenOpen, setScreen] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [manualOffline, setManualOffline] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  

  const handleMyAccount = (event) => {
    setScreen('account');
  }

  const handleLogout = (event) => {
    Logout(afterLogout);
  }

  const handleAdministrateurs = (event) => {
    setScreen('administrateurs');
  }

  const handleExploitations = (event) => {
    setScreen('exploitations');
  }

  const handleCapteurs = (event) => {
    setScreen('capteurs')
  }
  

  const handleAfterLogin = () => {
    window.location.reload(true);
  };

  
  const afterLogout = () => {
    window.location.reload(true);
  }

  useEffect(() => {
    
  }, [screenOpen]);

  if(AuthToken(true) == "")
  {
    return (
      <div className="h-100">
        <Login screenHandling={handleAfterLogin}/>
      </div>
      );
  }
  else
  {
    switch(screenOpen)
    {
      case 'settings':
        
      default:
        return (
          <div >
            
            <div className="container">
            <div className="row py-2 mb-3">
                <div className="col-3 text-start"><img src={logo_vigivel} className="mw-100 logo-header" /></div>
                <div className="col-6 pt-3 text-center"><h1 className="h2">Administration</h1></div>
                <div className="col-3 text-end">
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-user">
                      <FontAwesomeIcon icon={faUser} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleMyAccount} className="d-none">Mon compte</Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}>Deconnexion</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  
                </div>
              </div>
              <div id="maindashboard">
                <div className="row">
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleAdministrateurs}>
                      <div className="card-body row">
                        <div className="col-2"><img src={ico_admin} className="mw-100" /></div>
                        <div className="col-8">
                          <h2>Administrateurs</h2>
                          <div className="d-none d-md-block">Gestion des administrateurs de cette interface</div>
                        </div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleExploitations}>
                      <div className="card-body row">
                        <div className="col-2"><img rel="preload" src={ico_exploitations} className="mw-100" /></div>
                        <div className="col-8">
                          <h2>Exploitations</h2>
                          <div className="d-none d-md-block">Gestion des exploitations, de leurs utilisateurs et des capteurs associés</div>
                        </div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <div className="card" onClick={handleCapteurs}>
                      <div className="card-body row">
                        <div className="col-2"><img rel="preload" src={ico_capteurs} className="mw-100" /></div>
                        <div className="col-8">
                          <h2>Capteurs</h2>
                          <div className="d-none d-md-block">Gestion des capteurs de vélage</div>
                        </div>
                        <div className="col-2 text-right pos-relative"><div className="pos-right"><FontAwesomeIcon icon={faChevronRight} /></div></div>
                      </div>
                    </div>
                  </div>
                  
                  
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-muted fs-6"><em>Version {packageInfo.version}</em></div>
                <div className="col-8 text-end">
                  
                </div>
              </div>
              
            </div>
            <Administrateurs screenVisible={screenOpen=="administrateurs"?true:false} screenHandling={setScreen}/>
            <Capteurs screenVisible={screenOpen=="capteurs"?true:false} screenHandling={setScreen}/>
            <Exploitations screenVisible={screenOpen=="exploitations"?true:false} screenHandling={setScreen}/>
          </div>
        );
    }
  }
  
}

export default Dashboard;
