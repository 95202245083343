import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, CSSProperties } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {GetListeAPI, GetFicheApi, DeleteApi, SaveApi} from '../functions/api';
import SPSLoader from './SPSLoader';
import Modal from 'react-bootstrap/Modal';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'


function Exploitations(props) {

  const [stateWindowEdit, setStateWindowEdit] = useState(false);
  const [datas, SetDatas] = useState([]);
  const [theData, SetTheData] = useState([]);

  const [users, SetUsers] = useState([]);
  const [userData, SetUserData] = useState([]);

  const [devices, SetDevices] = useState([]);
  const [optionsDevices, setOptionsDevices] = useState([]);

  const [availableDevices, SetAvailableDevices] = useState([]);

  const [editAttributionDevice, setEditAttributionDevice] = useState({id:0, device_id: 0, farmer_device_id: ""});
  

  const [uniqueId, setUniqueId] = useState(0);  // id eleveur
  const [nom, setNom] = useState(""); // nom de l'eleveur
  const [prenom, setPrenom] = useState(""); //prenom de l'eleveur
  const [email, setEmail] = useState(""); //email de l'eleveur
  const [telephoneMobile, setTelephoneMobile] = useState("");
  const [telephoneFixe, setTelephoneFixe] = useState("");
  const [numElevage, setNumElevage] = useState("");
  const [fermeId, setFermeId] = useState(0);  // id ferme
  const [nomFerme, setNomFerme] = useState("");
  const [adresse, setAdresse] = useState(""); 
  const [commune, setCommune] = useState("");
  const [cp, setCP] = useState("");
  const [pays, setPays] = useState("");
  const [race, setRace] = useState("");

  const [filterSearch, setFilterSearch] = useState("");

  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [userfirstname, setUserFirstname] = useState("");
  const [userlastname, setUserLastname] = useState("");
  const [usertelephone_fixe, setUserTelephoneFixe] = useState("");
  const [usertelephone_mobile, setUserTelephoneMobile] = useState("");
  const [userpassword, setUserPassword] = useState("");
  const [userphone1_alert, setUserPhone1Alert] = useState("");
  const [userphone2_alert, setUserPhone2Alert] = useState("");

  const [stateModalUser, setStateModalUser] = useState(false);

  const [stateModalDevice, setStateModalDevice] = useState(false);
  
  var idtimer = 0;
  let [loading, setLoading] = useState(false);
  
  
  useEffect(() => {
    if(stateWindowEdit == false)
    {
      GetListeAPI("exploitations", filterSearch, SetDatas);
    }
    else
      GetListeAPI("devices", "" ,ChargeCapteurs);
    setLoading(false);
  }, [stateWindowEdit]);

  useEffect(() => {
    if(theData.length > 0 && theData[0].id != undefined)
    {
      const data = theData[0];
      setLoading(true);
      setUniqueId(data.id);
      setNom(data.nom?data.nom:'');
      setPrenom(data.prenom?data.prenom:'');
      setEmail(data.email?data.email:"");
      setTelephoneMobile(data.telephone_mobile?data.telephone_mobile:"");
      setTelephoneFixe(data.telephone_fixe?data.telephone_fixe:"");
      setNumElevage(data.num_elevage?data.num_elevage:"");
      setFermeId(data.ferme_id?data.ferme_id:0);
      setNomFerme(data.nom_ferme?data.nom_ferme:"");
      setAdresse(data.adresse?data.adresse:"");
      setCommune(data.commune?data.commune:"");
      setCP(data.code_postal?data.code_postal:"");
      setPays(data.pays?data.pays:"");
      setRace(data.race?data.race:"");

      // chargement des utilisateurs
      GetListeAPI("users", "&ferme_id="+data.ferme_id, SetUsers);
      GetListeAPI("attributions_devices", "&ferme_id="+data.ferme_id, SetDevices);

      setStateWindowEdit(true);
      setLoading(false);
    }
  }, [theData]);

  useEffect(() => {
    if(userData.length > 0 && userData[0].id != undefined)
    {
      const data = userData[0];
      setUserId(data.id);
      setUsername(data.username?data.username:'');
      setUseremail(data.email?data.email:'');
      setUserFirstname(data.first_name?data.first_name:"");
      setUserLastname(data.last_name?data.last_name:"");
      setUserTelephoneFixe(data.telephone_fixe?data.telephone_fixe:"");
      setUserTelephoneMobile(data.telephone_mobile?data.telephone_mobile:"");
      setUserPhone1Alert(data.phone1_alert?data.phone1_alert:"");
      setUserPhone2Alert(data.phone2_alert?data.phone2_alert:"");
      setUserPassword("");
      
      setStateModalUser(true);

    }
  }, [userData]);

 

  useEffect(() => {
    GetListeAPI("exploitations", filterSearch, SetDatas);
  }, [filterSearch]);
  
  const handleDeleteData = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cette exploitation ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteApi('exploitation', id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };

  const afterDelete = () => {
    GetListeAPI("exploitations", filterSearch, SetDatas);
  };

  const handleAdd = (event) => {
    clearFields();
    
    setStateWindowEdit(true);
  };

  const clearFields = () => {
    setUniqueId(0);
    setNom('');
    setPrenom('');
    setEmail("");
    setTelephoneMobile("");
    setTelephoneFixe("");
    setNumElevage("");
    setFermeId(0);
    setNomFerme("");
    setAdresse("");
    setCommune("");
    setCP("");
    setPays("");
    setRace("");
    SetUsers([]);
    SetDevices([]);
  }

  const handleCancel= (event) => {
    clearFields();
    setStateWindowEdit(false);
  };
  const handleClickEdit = (id) => {
    setLoading(true);
    GetFicheApi('exploitation', id, SetTheData);
  };

  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    saveExploitation(afterSubmit);
    
  }

  function saveExploitation(afterSave)
  {
    setLoading(true);
    const body = makeobject();
    SaveApi ('exploitation', body, afterSave);
  }

  const afterSubmit = () => {
    clearFields();
    setStateWindowEdit(false);
  }



  const handleClickUser = (id) => {
    // recherche de l'utilisateur
    GetFicheApi('user', id, SetUserData);
  }

  function afterSaveExploitation(result)
  {
    setLoading(false);
    GetFicheApi('exploitation', result.id, setExploitationDataBeforeAddUser);
    //setUniqueId(result.id);
    //addUser();
  }

  function setExploitationDataBeforeAddUser(data)
  {
    SetTheData(data);
    addUser();
  }

  const handleAddUser = () => {
    if(uniqueId == 0)
      {
        saveExploitation(afterSaveExploitation)
      }
      else
      {
        addUser();
      }
    
  }

  const handleAddUserDefaut = ()   => {
    // on affecte automatiquement tous les champs du contact exploitation
    setUserId(0);
    setUsername(prenom+'.'+nom);
    setUseremail(email);
    setUserFirstname(prenom);
    setUserLastname(nom);
    setUserTelephoneFixe(telephoneFixe);
    setUserTelephoneMobile(telephoneMobile);
    setUserPassword('');
    setUserPhone1Alert('');
    setUserPhone2Alert('');
    setStateModalUser(true);
  }

  function addUser()
  {
    clearFieldsUser();
    setStateModalUser(true);
  }

  const handleDeleteUser = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cet utilisateur ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteApi('user', id,  ChargeListeUsers);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });

    
  }

  const handleClickDevice = (id) => {
    // recherche de l'utilisateur
    GetFicheApi('attribution_device', id, editDevice);
    
  }

  function editDevice(data)
  {
    setEditAttributionDevice({id:data[0].id, device_id: data[0].device_id, farmer_device_id: data[0].farmer_device_id});
    setStateModalDevice(true);
  }

  const handleAddDevice = () => {
    clearFieldsDevice();
    setStateModalDevice(true);
  }
  const handleDeleteDevice = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce capteur de cette exploitation ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteApi('attribution_device', id,  ChargeListeDevices);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });

    
  }

  const handleClosedUser = () => {
    setStateModalUser(false);
  }

  const handleSubmitUser = () => {
    const body = {
      id: userId,
      ferme_id: fermeId,
      username: username,
      email: useremail,
      first_name: userfirstname,
      last_name: userlastname,
      telephone_mobile: usertelephone_mobile,
      telephone_fixe: usertelephone_fixe,
      password: userpassword
    };
    SaveApi ('user', body, afterSubmitUser);
  }

  const afterSubmitUser = () => {
    
    setStateModalUser(false);
    clearFieldsUser();
    ChargeListeUsers();
  }

  function ChargeCapteurs(datas)
  {
    var options = [];//{name:'', value: 0}
    for(var i = 0; i < datas.length; i++)
    {
      options.push({name: datas[i].dev_eui+" "+datas[i].crm_device_id, value: datas[i].id});
    }
    setOptionsDevices(options);
    
  }
  function ChargeListeUsers()
  {
    GetListeAPI("users", "&ferme_id="+fermeId, SetUsers);
  }

  function ChargeListeDevices()
  {
    GetListeAPI("attributions_devices", "&ferme_id="+fermeId, SetDevices);
  }

  function clearFieldsUser()
  {
    setUserId(0);
    setUsername('');
    setUseremail('');
    setUserFirstname('');
    setUserLastname('');
    setUserTelephoneFixe('');
    setUserTelephoneMobile('');
    setUserPassword('');
    setUserPhone1Alert('');
    setUserPhone2Alert('');
  }

  function clearFieldsDevice()
  {
    setEditAttributionDevice({id:0, device_id: 0, farmer_device_id: ""});
    
  }

  const handleSubmitDevice = () => {
    
    const body = {
      id: editAttributionDevice.id,
      device_id: editAttributionDevice.device_id,
      ferme_id: fermeId,
      farmer_device_id: editAttributionDevice.farmer_device_id,
      
    };
    SaveApi ('attribution_device', body, afterSubmitDevice);
  }

  const handleClosedDevice = () => {
    setStateModalDevice(false);
  }

  function afterSubmitDevice()
  {
    setStateModalDevice(false);
    ChargeListeDevices();
  }

  function makeobject()
  {
    const body = {
      id:uniqueId,
      nom: nom,
      prenom: prenom,
      email: email,
      telephone_mobile: telephoneMobile,
      telephone_fixe: telephoneFixe,
      num_elevage: numElevage,
      ferme_id: fermeId,
      ferme_nom: nomFerme,
      adresse: adresse,
      commune: commune, 
      code_postal: cp,
      pays: pays,
      race: race,

    };
    return body;
  }
    
  const handleBack = (event) => {
    
    props.screenHandling(0);
  };

  return (
    <div className={props.screenVisible?"animscreen visible":"animscreen hidden"} id="administrateurs_screen">
      <div className={stateWindowEdit==false?"liste containerxl":"liste containerxl hidden"} >
          
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6"><h1 className="h4 py-3">Exploitations</h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={handleAdd}><FontAwesomeIcon icon={faPlus} /></button></div>
            </div>
          
          <SPSLoader load={loading} />
          <div className="content-screen">
            <div className="container">
              <div className="row">
                <div className="col-md-10 text-center my-2">
                  <input type="text" className="form-control" placeholder='Recherche...' onChange={e => { setFilterSearch(e.target.value); } } value={filterSearch} />
                </div>
                <div className="col-md-2 text-center my-2">
                  {datas.length} enregistrement{datas.length > 1?'s':''}
                </div>
              </div>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="px-0">
                    {datas.length > 0?
                    <ul className="container liste-data">
                      {datas.map((r) =>(
                      <li className="row mb-2 alternate-color" key={r.id}  >
                          <div className="col-10" onClick={() => handleClickEdit(r.id)}>
                            <strong>{r.nomFerme} {r.prenom} {r.nom}</strong><br />
                            {r.adresse} {r.code_postal} {r.commune}<br />
                            {r.email} {r.telephone_fixe} {r.telephone_mobile}
                          </div>
                          <div className="col-2 text-end col-action">
                              <div className="tools-actions">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteData(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                              </div>
                          </div>
                      </li>
                      ))}
                    </ul>:<div className="text-muted text-center">Aucun enregistrement</div>
                    }
                    <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer une exploitation</button></div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
            
          
          
    </div>
    <div className={stateWindowEdit?"saisie containerxl":"saisie containerxl hidden"}>
      <div className="row headScreen">
        <div className="col-3 text-start py-2"><button className="btn btn-link" type="button" onClick={handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
        <div className="col-6"><h1 className="h4 py-3">Exploitation</h1></div>
        <div className="col-3"></div>
      </div>
      <SPSLoader load={loading} />
      <div className="container py-3">
        <form onSubmit={handleSubmit}>
          <div className="row">
          <div className="col-md-6">
              <input type="hidden" name="id" value={uniqueId} />
              <fieldset>
                <legend>Coordonnées</legend>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Nom de l'exploitation</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="NomFerme" value={nomFerme} onChange={e => { setNomFerme(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Adresse</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="Adresse" value={adresse} onChange={e => { setAdresse(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Code postal</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="CodePostal" value={cp} onChange={e => { setCP(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Commune</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="Commune" value={commune} onChange={e => { setCommune(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Pays</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="Pays" value={pays} onChange={e => { setPays(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Race</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="Race" value={race} onChange={e => { setRace(e.target.value); }} />
                  </div>
                </div>
                
              </fieldset>
            </div>
            <div className="col-md-6">
              <fieldset>
                <legend>Contact éleveur</legend>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Nom</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="Nom" value={nom} onChange={e => { setNom(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Prénom</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="Prenom" value={prenom} onChange={e => { setPrenom(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Email</label>
                  <div className="col-md-10">
                    <input type="email" className="form-control" name="Email" value={email} onChange={e => { setEmail(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Téléphone Mobile</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="TelephoneMobile" value={telephoneMobile} onChange={e => { setTelephoneMobile(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">Téléphone Fixe</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="TelephoneFixe" value={telephoneFixe} onChange={e => { setTelephoneFixe(e.target.value); }} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-md-2">N° élevage</label>
                  <div className="col-md-10">
                    <input type="text" className="form-control" name="NumElevage" value={numElevage} onChange={e => { setNumElevage(e.target.value); }} />
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="col-12">
              <fieldset>
                <div className="float-end">
                  <div className="btn-group" role="group" aria-label="Ajout d'utilisateurs">
                    <button type="button" className="btn btn-primary btn-sm" onClick={handleAddUser}>Ajouter</button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={handleAddUserDefaut}>Créer utilisateur par défaut</button>
                  </div>
                </div>
                <legend className="float-start d-inline w-auto">Utilisateurs</legend>
                
                {users.length > 0?
                <ul className="container liste-data">
                {users.map((r) =>(
                  <li className="row mb-2 alternate-color" key={r.id}  >
                      <div className="col-4" onClick={() => handleClickUser(r.id)}>
                        <strong>{r.first_name} {r.last_name}</strong>
                      </div>
                      <div className="col-6" onClick={() => handleClickUser(r.id)}>
                      {r.email} {r.telephone_fixe} {r.telephone_mobile}
                      </div>
                      <div className="col-2 text-end col-action">
                          <div className="tools-actions">
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteUser(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                          </div>
                      </div>
                  </li>
                  ))}
                  </ul>
                :
                <div className="text-center text-muted">Aucun utilisateur</div>
                }
              </fieldset>
            </div>

            <div className="col-12">
              <fieldset>
                <div className="float-end"><button type="button" className="btn btn-primary btn-sm" onClick={handleAddDevice}>Ajouter</button></div>
                <legend className="float-start d-inline w-auto">Capteurs</legend>
                
                {devices.length > 0?
                <ul className="container liste-data">
                {devices.map((r) =>(
                  <li className="row mb-2 alternate-color" key={r.id}  >
                      <div className="col-4" onClick={() => handleClickDevice(r.id)}>
                        <strong>{r.farmer_device_id}</strong>
                      </div>
                      <div className="col-6" onClick={() => handleClickDevice(r.id)}>
                      {r.dev_eui} 
                      </div>
                      <div className="col-2 text-end col-action">
                          <div className="tools-actions">
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteDevice(r.id)}>Enlever</button>
                          </div>
                      </div>
                  </li>
                  ))}
                  </ul>
                :
                <div className="text-center text-muted">Aucun capteur</div>
                }
              </fieldset>
            </div>
            
          </div>
          <div className="text-end mt-3 p-3 border-top">
            <button className="btn btn-primary" type="submit">Valider</button>
          </div>
        </form>
        
      </div>
      <Modal show={stateModalUser} onHide={handleClosedUser} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
          <Modal.Title>Utilisateur</Modal.Title>
          </Modal.Header>

          <Modal.Body>
                <form onSubmit={handleSubmitUser}>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Nom utilisateur</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="username" value={username} onChange={e => { setUsername(e.target.value); }} required />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Email</label>
                    <div className="col-md-9">
                      <input type="email" className="form-control" name="email" value={useremail} onChange={e => { setUseremail(e.target.value); }} required />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Prénom</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="first_name" value={userfirstname} onChange={e => { setUserFirstname(e.target.value); }} required />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Nom</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="last_name" value={userlastname} onChange={e => { setUserLastname(e.target.value); }} required />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Mot de passe</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="password" value={userpassword} onChange={e => { setUserPassword(e.target.value); }}  />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Mobile</label>
                    <div className="col-md-4">
                      <input type="text" className="form-control" name="telephone_mobile" value={usertelephone_mobile} onChange={e => { setUserTelephoneMobile(e.target.value); }}  />
                    </div>
                    <label className="col-md-1 text-end">Fixe</label>
                    <div className="col-md-4">
                      <input type="text" className="form-control" name="telephone_mobile" value={usertelephone_fixe} onChange={e => { setUserTelephoneFixe(e.target.value); }}  />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Mobile Alerte 1</label>
                    <div className="col-md-4">
                      <input type="text" className="form-control" name="phone1_alert" value={userphone1_alert} onChange={e => { setUserPhone1Alert(e.target.value); }}  />
                    </div>
                    <label className="col-md-1 text-end">Alerte 2</label>
                    <div className="col-md-4">
                      <input type="text" className="form-control" name="phone2_alert" value={userphone2_alert} onChange={e => { setUserPhone2Alert(e.target.value); }}  />
                    </div>
                  </div>
                </form>
          </Modal.Body>

          <Modal.Footer>
              <button type="button" className="btn btn-secondary" onClick={handleClosedUser}>Annuler</button>
              <button type="submit" className="btn btn-primary" onClick={handleSubmitUser}>Enregistrer</button>
          </Modal.Footer>
      </Modal>

      
      <Modal show={stateModalDevice} onHide={handleClosedDevice} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
          <Modal.Title>Capteurs</Modal.Title>
          </Modal.Header>

          <Modal.Body>
                <form onSubmit={handleSubmitDevice}>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Capteur</label>
                    <div className="col-md-9">
                    <SelectSearch search="true" autoComplete="false" options={optionsDevices} value={editAttributionDevice.device_id}  placeholder="Choisir le capteur"  onChange={(value) => { setEditAttributionDevice({...editAttributionDevice, device_id:value}) }} />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Référence</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="farmer_device_id" value={editAttributionDevice.farmer_device_id} onChange={e => { setEditAttributionDevice({...editAttributionDevice, farmer_device_id:e.target.value}) }} required />
                    </div>
                  </div>
                  
                </form>
          </Modal.Body>

          <Modal.Footer>
              <button type="button" className="btn btn-secondary" onClick={handleClosedDevice}>Annuler</button>
              <button type="submit" className="btn btn-primary" onClick={handleSubmitDevice}>Enregistrer</button>
          </Modal.Footer>
      </Modal>
    </div>
    </div>
  );

  
}

export default Exploitations;
